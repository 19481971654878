<template>
  <FormWrapper :is-loading="isLoading">
    <PageTitle
      title="Extension Update"
      slot="title"
      link="/helpContent/ExtensionUpdate"
    />
    <div>
      <FormRow v-for="(item, index) in extensionData" :key="index" style="padding-left: 10%;">
        <div class="col-sm-3">
          <InputIntegerNumber
          :label="item.Name"
          v-model="item.Extension"
        />
        </div>
         </FormRow>
    </div>
   
    <ButtonGroup style="padding-left: 10%;">
      <!-- <FormButton type="info" @click="()=>{$router.go(-1)}">Back</FormButton>     -->
      <FormButton type="info"  @click="prev()" v-if="pagination.page>1" >Previous</FormButton>    
          <FormButton type="success"  @click="sendData(0)">Save</FormButton>
          <FormButton type="info"  @click="sendData(1)" v-if="totalPage>pagination.page">Next</FormButton>
        </ButtonGroup>
        <Popup title="Success" type="success" :value="showMessage.isVisible"  @close="() => (showMessage.isVisible = false)" :closable="true">
            {{ showMessage.message }}
            <div slot="footer">
                <FormButton @click="()=>{$router.go(-1)}">Ok</FormButton>
                <FormButton @click="() => (showMessage.isVisible = false)"
          >Stay On Same Page</FormButton
        >
            </div>
        </Popup>
  </FormWrapper>
  
</template>

<script>
import FormWrapper from "Components/form/FormWrapper";
import PageTitle from "Components/layout/PageTitle";
import loadingMixin from "Mixins/loadingMixin";
import {getExtensionUserList,updateExtension } from "../api";

export default {
  name: "List",
  mixins: [loadingMixin],
  components: {
    FormWrapper,
    PageTitle,
  },
  data() {
    return {
      filtering: {
        staffId: null,
        date:'',
      },
      sorting: {
          key: "id",
          order: "asc",
        },
        pagination: {
          perPage: 10,
          page: 1,
          total: 0,
        },
        totalPage:0,
      extensionData: [],
      showMessage:{
                    isVisible: false,
                    message: ''
                },
    };
  },
  computed: {
    userData() {
      return this.$store.state.user.user;
    },
  },
  watch: {},
  created() {
    this.getDataByFilter();
  },
  methods: {
    search() {
      this.pagination.page = 1;
      this.getDataByFilter();
    },
    prev(){
      this.pagination.page=this.pagination.page-1;
      if(this.totalPage>0){
        this.getDataByFilter();
      }

    },
    getDataByFilter() {
      setTimeout(() => {
            this.showLoader();
            const { key, order } = this.sorting;
              const { perPage, page } = this.pagination;

              const filters = {};
              const data = {
                filterjson: {
                  filter: [filters],
                  sort: [{ key, order }],
                  paging: [
                    {
                      startIndex: page,
                      pageSize: perPage,
                    },
                  ],
                },
              };
            getExtensionUserList(data)
              .then(this.handleResponse)
              .catch(this.handleError);
          
        },50);
      
    },
    pageChange(page) {                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                     
      this.pagination.page = page;
      // this.getDataByFilter();
    },
    sortChange(data) {
        this.sorting.key = data.key;
        this.sorting.order = data.order;
        this.getDataByFilter();
      },
    handleResponse(response) {
      this.hideLoader();
      this.extensionData = response.data;
      this.message = response.data[0].Message;
      // this.pagination.total=60
      // this.totalPage=parseInt(response.NumberOfRecords/10);
      let rem=parseFloat(response.NumberOfRecords%10);
      let res=parseInt(response.NumberOfRecords/10);
      if(rem>0){
        this.totalPage=res+1;
      }
      else{
        this.totalPage=res; 
      }
// console.log(this.totalPage)
    },
    sendData(via){
if(via==0){
  const data={extensionJson:JSON.stringify(this.extensionData)};
            updateExtension(data).then((res)=>{
              this.showMessage.isVisible=true;
                            this.showMessage.message=res.data[0].message;
            })
}
else{
  this.pagination.page=this.pagination.page+1;
          if ((this.totalPage>=this.pagination.page) && (this.pagination.page>0)) {
            const data={extensionJson:JSON.stringify(this.extensionData)};
            updateExtension(data).then((res)=>{
              this.showMessage.isVisible=true;
                            this.showMessage.message=res.data[0].message;
            })
            this.getDataByFilter();
          }
}
          
    }
  },
};
</script>

